<script lang="ts">
	import Hero from '$lib/components/PageSection/Hero.svelte';
	import CoursesList from '$lib/components/Course/CoursesList.svelte';
	import HowItWorksSection from '$lib/components/PageSection/Marketing/HowItWorksSection.svelte';
	import type { AllCoursesStore } from '$houdini';
	import { getTranslate } from '@tolgee/svelte';
	import JourneySection from '$lib/components/PageSection/Marketing/JourneySection.svelte';
	import Button from '$lib/components/Button/Button.svelte';
	import CourseCard from '$lib/components/Course/CourseCard.svelte';
	import { page } from '$app/stores';
	import NewsletterOrWishSections from '$lib/components/PageSection/NewsletterOrWishSections.svelte';
	import MetaHead from '$lib/components/SEO/MetaHead.svelte';

	export let data: { AllCourses: AllCoursesStore };

	const { t } = getTranslate();
	const tolgeePrefix = 'homepage';

	const journeyItems = [
		{
			title: $t(`${tolgeePrefix}.journeyItem1.title`),
			description: $t(`${tolgeePrefix}.journeyItem1.description`),
			icon: 'SparklesIcon'
		},
		{
			title: $t(`${tolgeePrefix}.journeyItem2.title`),
			description: $t(`${tolgeePrefix}.journeyItem2.description`),
			icon: 'SpeachBubbleIcon'
		},
		{
			title: $t(`${tolgeePrefix}.journeyItem3.title`),
			description: $t(`${tolgeePrefix}.journeyItem3.description`),
			icon: 'HandIcon'
		}
	];

	$: ({ AllCourses } = data);

	$: metaData = $AllCourses?.data?.homePages?.[0]?.seo;
	// $: learningPaths = $AllCourses.data?.learningPaths;
</script>

<MetaHead
	title={metaData?.metaTitle}
	metaTitle={metaData?.metaTitle}
	metaDescription={metaData?.metaDescription}
	ogUrl={$page.url.toString()}
	ogTitle={metaData?.ogTitle}
	ogDescription={metaData?.ogDescription}
	ogImage={metaData?.ogImage?.url}
/>

<Hero
	additionalClassLg="bg-hero-1"
	title={$t('homepage.hero-section.title')}
	subtitle={$t('homepage.hero-section.subtitle')}
	buttonText={$page.data.session ? $t('cta_toCourses') : $t('homepage.hero-section.buttonText')}
	href={$page.data.session ? '/courses' : '/auth/signup'}
/>

<HowItWorksSection additionalClass="x-container mt-24" {tolgeePrefix} />

<JourneySection additionalClass="x-container mt-36" {tolgeePrefix} {journeyItems} />

<div class="x-container flex justify-center mt-12">
	{#if $page.data.session}
		<a href="/courses"> <Button additionalClass="uppercase">{$t('cta_toCourses')}</Button></a>
	{:else}
		<a href="/auth/signup">
			<Button additionalClass="uppercase">{$t('homepage.journey.cta')}</Button></a
		>
	{/if}
</div>

<!-- <div class="x-container mt-32 flex justify-center">
	<LearningPathCardsBar
		title={$t(`${tolgeePrefix}.learningPathCardsBar.title`)}
		cards={learningPaths?.map((element) => ({
			title: element.title,
			slug: `learning-paths/${element.slug}`,
			shortDescription: element.shortDescription,
			imageUrl: element.thumbnail.url
		}))}
	/>
</div> -->

<section class="bg-light mt-32">
	<div class="x-container">
		<h1 class="mb-12 text-4xl font-bold">{@html $t('homepage.courses-section.headline')}</h1>
		{#if $AllCourses?.data?.courses?.length}
			<CoursesList>
				{#each $AllCourses.data.courses.filter((c) => !!c && c.customer === 'Deepskydata' && !c.isUsedForSales) as course}
					<CourseCard
						href="/courses/{course.slug}"
						title={course.name}
						imageData={course.thumbnail}
					/>
				{/each}
			</CoursesList>
		{/if}
	</div>
</section>

<NewsletterOrWishSections additionalClass="mt-32" />
