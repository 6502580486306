<script lang="ts">
	import { classes } from '$lib/utils';
	import { getTranslate } from '@tolgee/svelte';
	import HeartIcon from '$lib/components/Icon/HeartIcon.svelte';
	import HandIcon from '$lib/components/Icon/HandIcon.svelte';
	import SpeachBubbleIcon from '$lib/components/Icon/SpeachBubbleIcon.svelte';
	import howitworks from '$lib/assets/howitworks.png';

	/**
	 * tolgeePrefix
	 * Das ist der prefix für die tolgee keys, die in dieser component verwendet werden.
	 * Dadruch können wir diese Component auf mehreren Pages mit unterschiedlichem Content füllen oder mit demselben – wenn wir wollen. :)
	 **/
	export let tolgeePrefix: string;
	export let additionalClass = '';

	const { t } = getTranslate();
</script>

<section class={classes('bg-white', additionalClass)}>
	<div class="flex flex-col-reverse lg:flex-row justify-center items-center">
		<div class="w-full">
			<div class="w-full">
				<img src={howitworks} alt="Man and woman infront of computer" class="object-contain" />
			</div>
		</div>
		<div class="lg:ml-[55px] mb-[32px] lg:mb-0 ">
			<h2 class="mb-4 text-4xl font-bold">
				{@html $t(`${tolgeePrefix}.hiw-section.title`)}
			</h2>

			<p class="font-normal mb-[32px]">
				{@html $t(`${tolgeePrefix}.hiw-section.subtitle`)}
			</p>

			<div class="flex flex-row mb-[32px] ">
				<div class="mr-[30px] pt-[10px]">
					<HeartIcon />
				</div>
				<div class="flex flex-col ">
					<h3 class="text-lg lg:text-xl">
						{@html $t(`${tolgeePrefix}.hiw-section.icontitle1`)}
					</h3>
					<p class="text-base lg:text-lg">
						{@html $t(`${tolgeePrefix}.hiw-section.iconparagraph1`)}
					</p>
				</div>
			</div>
			<div class="flex flex-row mb-[32px]">
				<div class="mr-[30px] pt-[10px]">
					<SpeachBubbleIcon additionalClass="w-[36px] h-[30.73px]" />
				</div>
				<div class="flex flex-col">
					<h3 class="lg:text-xl">{@html $t(`${tolgeePrefix}.hiw-section.icontitle2`)}</h3>
					<p class="lg:text-lg">{@html $t(`${tolgeePrefix}.hiw-section.iconparagraph2`)}</p>
				</div>
			</div>
			<div class="flex flex-row">
				<div class="mr-[30px] pt-[10px]">
					<HandIcon />
				</div>
				<div class="flex flex-col">
					<h3 class="lg:text-xl">{@html $t(`${tolgeePrefix}.hiw-section.icontitle3`)}</h3>
					<p class="lg:text-lg">{@html $t(`${tolgeePrefix}.hiw-section.iconparagraph3`)}</p>
				</div>
			</div>
		</div>
	</div>
</section>
