<script lang="ts">
	import { classes } from '$lib/utils';
	export let additionalClass = '';
</script>

<svg
	width="38"
	height="33"
	viewBox="0 0 38 33"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
	class={classes('w-[36px] h-[30.73px] ', additionalClass)}
>
	<path
		d="M1.68509 6.5559C2.13739 5.46396 2.80034 4.47181 3.63608 3.63608C4.47181 2.80034 5.46396 2.13739 6.5559 1.68509C7.64784 1.2328 8.81817 1 10.0001 1C11.182 1 12.3523 1.2328 13.4443 1.68509C14.5362 2.13739 15.5283 2.80034 16.3641 3.63608L19.0001 6.27208L21.6361 3.63608C23.3239 1.94824 25.6131 1.00002 28.0001 1.00002C30.387 1.00002 32.6762 1.94824 34.3641 3.63608C36.0519 5.32392 37.0001 7.61312 37.0001 10.0001C37.0001 12.387 36.0519 14.6762 34.3641 16.3641L19.0001 31.7281L3.63608 16.3641C2.80034 15.5283 2.13739 14.5362 1.68509 13.4443C1.2328 12.3523 1 11.182 1 10.0001C1 8.81817 1.2328 7.64784 1.68509 6.5559Z"
		stroke="#111928"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
</svg>
