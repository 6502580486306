export default {
    "name": "AllCourses",
    "kind": "HoudiniQuery",
    "hash": "cac751846d26dc4e8e4f681a5e880b7fe21dc6614d58193677a8697160a8a938",

    "raw": `query AllCourses {
  courses(first: 100) {
    id
    name
    shortDescription
    slug
    thumbnail {
      ...Image
      id
    }
    lessons(first: 100) {
      id
      slug
      name
      description
      duration
    }
    customer
    isUsedForSales
  }
  learningPaths(orderBy: index_ASC) {
    id
    title
    slug
    shortDescription
    thumbnail {
      ...Image
      id
    }
  }
  homePages(first: 1) {
    seo {
      metaTitle
      metaDescription
      ogTitle
      ogDescription
      ogImage {
        url
        id
      }
      id
    }
    id
  }
}

fragment Image on Asset {
  url(transformation: {document: {output: {format: webp}}})
  sm: url(
    transformation: {image: {resize: {width: 320}}, document: {output: {format: webp}}}
  )
  md: url(
    transformation: {image: {resize: {width: 640}}, document: {output: {format: webp}}}
  )
  lg: url(
    transformation: {image: {resize: {width: 1024}}, document: {output: {format: webp}}}
  )
  xl: url(
    transformation: {image: {resize: {width: 1280}}, document: {output: {format: webp}}}
  )
  social: url(
    transformation: {image: {resize: {width: 1200, height: 630, fit: crop}}, document: {output: {format: png}}}
  )
  width
  id
  __typename
}
`,

    "rootType": "Query",

    "selection": {
        "fields": {
            "courses": {
                "type": "Course",
                "keyRaw": "courses(first: 100)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "ID",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "nullable": true,
                            "visible": true
                        },

                        "shortDescription": {
                            "type": "String",
                            "keyRaw": "shortDescription",
                            "nullable": true,
                            "visible": true
                        },

                        "slug": {
                            "type": "String",
                            "keyRaw": "slug",
                            "nullable": true,
                            "visible": true
                        },

                        "thumbnail": {
                            "type": "Asset",
                            "keyRaw": "thumbnail",

                            "selection": {
                                "fields": {
                                    "url": {
                                        "type": "String",
                                        "keyRaw": "url(transformation: {document: {output: {format: webp}}})",
                                        "visible": true
                                    },

                                    "sm": {
                                        "type": "String",
                                        "keyRaw": "sm(transformation: {image: {resize: {width: 320}}, document: {output: {format: webp}}})",
                                        "visible": true
                                    },

                                    "md": {
                                        "type": "String",
                                        "keyRaw": "md(transformation: {image: {resize: {width: 640}}, document: {output: {format: webp}}})",
                                        "visible": true
                                    },

                                    "lg": {
                                        "type": "String",
                                        "keyRaw": "lg(transformation: {image: {resize: {width: 1024}}, document: {output: {format: webp}}})",
                                        "visible": true
                                    },

                                    "xl": {
                                        "type": "String",
                                        "keyRaw": "xl(transformation: {image: {resize: {width: 1280}}, document: {output: {format: webp}}})",
                                        "visible": true
                                    },

                                    "social": {
                                        "type": "String",
                                        "keyRaw": "social(transformation: {image: {resize: {width: 1200, height: 630, fit: crop}}, document: {output: {format: png}}})",
                                        "visible": true
                                    },

                                    "width": {
                                        "type": "Float",
                                        "keyRaw": "width",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "ID",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "__typename": {
                                        "type": "String",
                                        "keyRaw": "__typename",
                                        "visible": true
                                    }
                                },

                                "fragments": {
                                    "Image": {
                                        "arguments": {}
                                    }
                                }
                            },

                            "visible": true
                        },

                        "lessons": {
                            "type": "Lesson",
                            "keyRaw": "lessons(first: 100)",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "ID",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "slug": {
                                        "type": "String",
                                        "keyRaw": "slug",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "duration": {
                                        "type": "Int",
                                        "keyRaw": "duration",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "customer": {
                            "type": "Customer",
                            "keyRaw": "customer",
                            "visible": true
                        },

                        "isUsedForSales": {
                            "type": "Boolean",
                            "keyRaw": "isUsedForSales",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "learningPaths": {
                "type": "LearningPath",
                "keyRaw": "learningPaths(orderBy: index_ASC)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "ID",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "title": {
                            "type": "String",
                            "keyRaw": "title",
                            "nullable": true,
                            "visible": true
                        },

                        "slug": {
                            "type": "String",
                            "keyRaw": "slug",
                            "nullable": true,
                            "visible": true
                        },

                        "shortDescription": {
                            "type": "String",
                            "keyRaw": "shortDescription",
                            "nullable": true,
                            "visible": true
                        },

                        "thumbnail": {
                            "type": "Asset",
                            "keyRaw": "thumbnail",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "url": {
                                        "type": "String",
                                        "keyRaw": "url(transformation: {document: {output: {format: webp}}})",
                                        "visible": true
                                    },

                                    "sm": {
                                        "type": "String",
                                        "keyRaw": "sm(transformation: {image: {resize: {width: 320}}, document: {output: {format: webp}}})",
                                        "visible": true
                                    },

                                    "md": {
                                        "type": "String",
                                        "keyRaw": "md(transformation: {image: {resize: {width: 640}}, document: {output: {format: webp}}})",
                                        "visible": true
                                    },

                                    "lg": {
                                        "type": "String",
                                        "keyRaw": "lg(transformation: {image: {resize: {width: 1024}}, document: {output: {format: webp}}})",
                                        "visible": true
                                    },

                                    "xl": {
                                        "type": "String",
                                        "keyRaw": "xl(transformation: {image: {resize: {width: 1280}}, document: {output: {format: webp}}})",
                                        "visible": true
                                    },

                                    "social": {
                                        "type": "String",
                                        "keyRaw": "social(transformation: {image: {resize: {width: 1200, height: 630, fit: crop}}, document: {output: {format: png}}})",
                                        "visible": true
                                    },

                                    "width": {
                                        "type": "Float",
                                        "keyRaw": "width",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "ID",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "__typename": {
                                        "type": "String",
                                        "keyRaw": "__typename",
                                        "visible": true
                                    }
                                },

                                "fragments": {
                                    "Image": {
                                        "arguments": {}
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "homePages": {
                "type": "HomePage",
                "keyRaw": "homePages(first: 1)",

                "selection": {
                    "fields": {
                        "seo": {
                            "type": "Seo",
                            "keyRaw": "seo",

                            "selection": {
                                "fields": {
                                    "metaTitle": {
                                        "type": "String",
                                        "keyRaw": "metaTitle",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "metaDescription": {
                                        "type": "String",
                                        "keyRaw": "metaDescription",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "ogTitle": {
                                        "type": "String",
                                        "keyRaw": "ogTitle",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "ogDescription": {
                                        "type": "String",
                                        "keyRaw": "ogDescription",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "ogImage": {
                                        "type": "Asset",
                                        "keyRaw": "ogImage",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "url": {
                                                    "type": "String",
                                                    "keyRaw": "url",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "ID",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "id": {
                                        "type": "ID",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "id": {
                            "type": "ID",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "policy": "CacheOrNetwork",
    "partial": false
};

"HoudiniHash=678c3d6b13fd120b2a57f8b6e5733a873ea421c45909c09c1b8588a13f0d6ec0";